/* Default Button styling */
.center-button {
    display: inline-flex; /* Keep inline */
    align-items: center;
    justify-content: center;
    width: 3rem; /* Adjust size to fit inline with text */
    height: 3rem;
    border: none;
    border-radius: 50%;
    background-color: white;
    border: 2px solid white;
    cursor: pointer;
    outline: none;
    color: #000;
    font-size: 20px;
    overflow: hidden;
    transition: color 0.5s ease, box-shadow 0.5s ease, width 0.5s ease, height 0.5s ease;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    animation: deflate 0.5s forwards;
    cursor: none;
    vertical-align: middle; /* Ensure it's aligned with text */
}

.center-button:hover {
    /* Change size and shadow smoothly on hover */
    animation: grow 0.5s forwards;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

@keyframes grow {
    0% {
        width: 3rem;
        height: 3rem;
    }
    100% {
        width: 5rem;
        height: 5rem;
    }
}

@keyframes deflate {
    0% {
        width: 5rem;
        height: 5rem;
    }
    100% {
        width: 3rem;
        height: 3rem;
    }
}

.center-button.inverted {
    background-color: black;
    border: 2px solid black;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Overlay styles */
.overlay {
    position: fixed; /* Use fixed to ensure it stays on top of everything */
    background-color: white; /* Default overlay background */
    border-radius: 50%; /* Start with a circle */
    animation: expandOverlay 1s forwards; /* Smooth expanding animation */
    z-index: 10000; /* Ensure it is above all other elements, including the header */
    top: 50%; /* Center the overlay vertically */
    left: 50%; /* Center the overlay horizontally */
    transform: translate(-50%, -50%) scale(1); /* Start at the center */
    transform-origin: center center; /* Expand from the center */
}

.overlay.inverted {
    background-color: black; /* Inverted overlay background */
}

@keyframes expandOverlay {
    from {
        transform: translate(-50%, -50%) scale(1); /* Start small at the center */
        border-radius: 50%; /* Keep the circular shape initially */
    }
    to {
        transform: translate(-50%, -50%) scale(100); /* Expand fully to cover the screen */
        border-radius: 0%; /* Morph into a rectangle as it fills the screen */
    }
}
