body, .App-header, .column-link {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
}

.portfolio-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    min-height: calc(100vh - 5rem);
}

/* Title styling */
.App-header h1 {
    display: inline-block;
    font-size: 3rem;
    margin: 0 auto 2rem;
    padding: 1rem 2rem;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
    backdrop-filter: blur(5px);
    color: white;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

/* Columns container */
.columns-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    background-color: transparent;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
}

/* Default (non-inverted) mode:
   - Default: Dark background, white text
   - Hover: Light background, black text */
.column-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
    min-width: 200px;
    padding: 1.5rem;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    color: white;
    transition: background-color 0.3s, color 0.3s;
    z-index: 20;
    min-height: 60vh;
}

.column-link:hover {
    background-color: rgba(255,255,255,0.7);
    color: black;
    cursor: pointer;
}

/* Inverted mode:
   - Default: Light background, black text
   - Hover: Dark background, white text */
.App.inverted .column-link {
    background-color: rgba(255,255,255,0.7);
    color: black;
}

.App.inverted .column-link:hover {
    background-color: rgba(0,0,0,0.7);
    color: white;
    cursor: pointer;
}

.icon-wrapper {
    margin-bottom: 1rem;
}

.column-link h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.column-link p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

/* Responsive */
@media (max-width: 768px) {
    .columns-container {
        flex-direction: column;
        gap: 1rem;
    }

    .column-link {
        padding: 1rem;
    }

    .App-header h1 {
        font-size: 2.5rem;
    }
}
