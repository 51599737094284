/* Basic message styles */
.rotate-message {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding: 1rem 2rem;
    border-radius: 10px;
    z-index: 2000;
    text-align: center;
    font-size: 1.2rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

@media (orientation: portrait) {
    .rotate-message {
        display: block;
    }
}

/* Styles for inverted mode */
.rotate-message.inverted {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
}

