.columns-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem; /* Adjust spacing between columns */
    padding: 1rem;
    background-color: transparent; /* Transparent container */
    position: relative; /* Ensure z-index works */
    z-index: 10; /* Make sure it is above the particles */
}

.about-content {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    flex-direction: column; /* Stack items vertically */
    gap: 1rem; /* Add spacing between elements */
    padding: 2rem;
    min-height: calc(100vh - 5rem); /* Full height minus header height */
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
    min-width: 200px;
    padding: 1.5rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    color: white;
    transition: background-color 0.3s, color 0.3s;
    z-index: 20;
    min-height: 60vh;
}

.column h2 {
    font-size: 2rem;
    margin-bottom: 1rem; /* Optional: add spacing between elements */
}

.column p {
    font-size: 1.2rem;
    margin-bottom: 1rem; /* Optional: spacing between elements */
}

.icon-wrapper {
    margin-bottom: 1rem;
}

.inverted .column {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    backdrop-filter: blur(5px);
}

/* Title Styling */
.App-header h1 {
    display: inline-block; /* Ensure the width wraps around the content */
    font-size: 3rem;
    margin: 0 auto 2rem; /* Center align and add spacing below */
    padding: 1rem 2rem; /* Add padding for spacing */
    border-radius: 10px; /* Rounded corners */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    backdrop-filter: blur(5px); /* Reduced blur effect for better particle visibility */
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for inverted mode */
    text-align: center; /* Center align text inside */
}

/* Inverted mode for the title */
.App.inverted .App-header h1 {
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent light background */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Match inverted column shadow */
    backdrop-filter: blur(5px); /* Reduced blur effect for better particle visibility */
    color: black; /* Adjust text color for inverted mode */
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5); /* Adjust shadow for readability */
}

@media (max-width: 768px) {
    .columns-container {
        flex-direction: column; /* Stack columns vertically on smaller screens */
        gap: 1rem;
    }

    .column {
        padding: 1rem;
    }

    .App-header h1 {
        font-size: 2.5rem; /* Smaller title font for mobile */
    }
}
