/* src/App.css */
.App {
    text-align: center;
    height: 100vh;
    overflow: hidden;
}

/* Ensure the cursor is hidden for the entire document */
body {
    cursor: none;
}

/* Ensure links do not show the cursor */
.custom-link {
    cursor: none;
}

/* Handle any other interactive elements that might show a cursor */
button, input, textarea {
    cursor: none;
}